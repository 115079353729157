import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Me`}</h2>
    <p>{`Hi, thanks for using the theme! You can find more themes at `}<a parentName="p" {...{
        "href": "https://themes.lekoarts.de"
      }}>{`themes.lekoarts.de`}</a>{` and visit my `}<a parentName="p" {...{
        "href": "https://www.lekoarts.de"
      }}>{`personal website`}</a>{` to learn more about Gatsby. Minimalistic portfolio/photography site with masonry grid, page transitions and big images. Themeable with Theme UI.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      